import { Vue, Component } from 'vue-property-decorator'
import { cms } from '@/contentful';
import { Route, Next } from 'vue-router';

import Hero from '@/components/page-builder/Hero.vue';
import FullWidthCTA from '@/components/page-builder/FullWidthCTA.vue';
import OverviewTextAndImage from '@/components/page-builder/OverviewTextAndImage.vue';
import Quadtych from '@/components/page-builder/Quadtych.vue';
import Slider from '@/components/page-builder/Slider.vue';

import LoadingIcon from '../../../public/assets/loading.svg';

@Component<PageBuilder>({
  metaInfo() {
    return {
      title: this.pageMeta.title,
      meta: [{ name: 'description', content: this.pageMeta.description }]
    };
  },
  beforeRouteLeave(to: Route, from: Route, next: Next) {
    this.content = [];
    this.getContent();
    next();
  },
  components: {
    Hero,
    FullWidthCTA,
    OverviewTextAndImage,
    LoadingIcon,
    Quadtych
  }
})

export default class PageBuilder extends Vue {
  loading: boolean = true;
  content: Array<any> = [];
  context: { title: string, description: string } = {
    title: '', description: ''
  };

  created(): void {
    this.getContent();
  }

  getContent(): void {
    cms.getEntries({ 'content_type': 'pageBuilder', include: 2 })
      .then(
        (entry: any) => {
          entry.items.map((item: any) => {
            if (item.fields.slug === this.$route.name) {
              this.content = item.fields.contentModules;
              this.context.title = item.fields.seoTitle;
              this.context.description = item.fields.seoDescription;
            }
          });
        }
      )
      .finally(() => this.loading = false);
  }

  get pageMeta() {
    return this.context;
  }

  setComponent(id: string): any {
    switch (id) {
      case 'pageBuilderHero':
        return Hero;
      case 'pageBuilderFullWidthCta':
        return FullWidthCTA;
      case 'pageBuilderOverviewTextAndImage':
        return OverviewTextAndImage;
      case 'pageBuilderQuadtych':
        return Quadtych;
      case 'pageBuilderSlider':
        return Slider;
    }
  }
}
